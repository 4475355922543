import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React from "react";
import { useLocation } from "@reach/router";
import BannerTop from "@/components/Careers/BannerTop";
import MoreInfo from "@/components/Careers/MoreInfo";
import BannerTwo from "@/components/Careers/BannerTwo";
import BannerDown from "@/components/Careers/BannerDown";
import Jobs from "@/components/Careers/Jobs";
import Partners from "@/components/Home/Partners";
import BannerD2 from "@/components/Careers/BannerD2";

const CareersP = () => {
  return (
    <Layout>
      <SEO title="Careers" canonical={`${useLocation().host}`} />
      <Breadcrumb
        imgUrl="/images/careers/v2.jpeg"
        name="Careers"
        pathName="Careers"
      />
      <BannerTop />
      <MoreInfo />
      <BannerTwo />
      <BannerDown />
      <Jobs />
      <Partners />
      <BannerD2 />
    </Layout>
  );
};

export default CareersP;
