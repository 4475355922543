import React from "react";

const data = [
  {
    title: "Equal Opportunity",
    description:
      "It is the policy and practice of MEKCO Group to provide all employees with a working environment free from all forms of discrimination and harassment. It is the policy of MEKCO Group to recruit, hire, promote, and take other personnel actions without regard to any protected class, including but not limited to race, color, religion, sex, age, national origin, disability, sexual orientation, gender identity, genetic information or protected veteran status, and to provide equal employment opportunities for all employees and employment candidates. ​​MEKCO Group's policy is to make all employment decisions on the basis of an individual’s job related qualifications, abilities, and performance – not on the basis of personal characteristics unrelated to successful job performance.",
    imagen: "/images/careers/c1.png",
  },
  {
    title: "Diversity",
    description:
      "We recognize that to win we have to do some things differently, and see ourselves in the context of the greater company and the changing world we live in. Seeking to develop and sustain a diverse and inclusive workforce is a commitment undertaken in order to best serve all of our customers, by attracting, developing and retaining the best and brightest talent worldwide. Diversity at MEKCO Group means embracing the differences that exist in all stakeholders; including our employees, customers, suppliers and the communities we serve. It means more than gender, race, age, sexual orientation, ethnic or national differences, religion and beliefs.It also includes the diversity of our experiences, thinking styles and cultures. It is by embracing our differences, and appreciating them, that we can create an inclusive and respectful workplace in which each person is empowered, has the ability to grow and develop, and is recognized for their contributions. Our ability and commitment to harness the strengths of all of our employees makes MEKCO Group a great place to work. At MEKCO Group, we believe it is critical to our success to have every employee engaged, fully developed and empowered. We accomplish this by creating and sustaining an inclusive work environment which attracts, supports and develops a diverse team of outstanding and dedicated employees.",
    imagen: "/images/careers/c2.png",
  },
  {
    title: "Veterans",
    description:
      "At MEKCO Group, we are proud to help men and women successfully transition from military into civilian careers. Join us and you will find opportunities to take on long-term challenging assignments.",
    imagen: "/images/careers/c3.png",
  },
];
const MoreInfo = () => {
  const reduceSentence = (el: any) => {
    try {
      return el.description
        .split(/\.\s+/)
        .reduce(
          (acc: string[], oracion: string, i: number, arr: string[]): any => {
            if (i % 2 === 0) {
              // si el índice es par, agrupar con la oración siguiente
              acc.push(
                `${oracion} ${
                  arr[i + 1].length > 0 ? ". " + arr[i + 1] + "." : ""
                }`,
              ); // agregar par de oraciones al arreglo de oraciones agrupadas
            }
            return acc;
          },
          [],
        )
        .map((e, n) => {
          if (n === 0) {
            return (
              <p key={n}>
                {" "}
                <img src={el.imagen} alt="" className="img-fluid bg_image" />
                {e.replace(/(\.\.)/g, ".")}
              </p>
            );
          }
          return <p key={n}>{e.replace(/(\.\.)/g, ".")}</p>;
        });
    } catch (error) {}
  };
  return (
    <div className="container mt--40 mb--60">
      {data.map((el, inx) => (
        <div key={`${el.title.split(" ")[0]}-${inx}`}>
          <div className="content-info-c">
            <h4>{el.title}</h4>
            {reduceSentence(el)}
          </div>
          <div className="clearfix"></div>
        </div>
      ))}
    </div>
  );
};

export default MoreInfo;
