import { useViewport } from "@/hooks/useViewport";
import React from "react";

const BannerTop = () => {
  const { isMobile } = useViewport();

  return (
    <div className="banner-carees-wrapper">
      <div className="container">
        <p>
          Mekco Group understands that integrity, loyalty,{!isMobile && <br />}{" "}
          and experience are the foundation for good business and is committed
          to using these three core principles in everything we do:
        </p>
      </div>
    </div>
  );
};

export default BannerTop;
