/* eslint-disable no-irregular-whitespace */
import React from "react";

const BannerTwo = () => {
  return (
    <div className="bannerTwoWrapper">
      <div className="container">
        <div className="rts-business-goal mt--0 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="business-goal-one">
                  <img
                    className="top bg_image"
                    src="/images/careers/b1.png"
                    alt="Business_Goal"
                  />
                  <img
                    className="small bg_image"
                    src="/images/business-goal/01.jpg"
                    alt="Business_Goal"
                  />
                </div>
              </div>
              <div className="col-lg-6 mt--35 mt_md--70 mt_sm--70 text-bt">
                <div className="business-goal-right">
                  <p>
                    At <strong>MEKCO Group,</strong> we recognize how important
                    your career and benefits are to you and your family.
                  </p>
                  <p>
                    We offer a full suite of benefits including medical, dental,
                    vision, short- and long-term disability coverage, accident
                    insurance, critical illness insurance, basic and
                    supplemental life insurance, employee assistance plan,
                    retirement savings and matching, tuition reimbursement and
                    other developmental opportunities.
                  </p>
                  <p>
                    We are committed to supporting the way you live and work.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerTwo;
