import React from "react";

const BannerD2 = () => {
  return (
    <div className="bd-wrapper position-relative">
      <div className="container">
        <h2 className="mx-auto position-relative">
          Can’t find your perfect role?
        </h2>
        <p className="position-relative">
          Can&apos;t find a position that matches your profile?
          <br />
          Send us an email to{" "}
          <a href="mailto:customerservice@mekcogroup.com">
            customerservice@mekcogroup.com
          </a>{" "}
          and let us know about your work experience.
        </p>
      </div>
    </div>
  );
};

export default BannerD2;
