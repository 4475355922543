import React from "react";

const BannerDown = () => {
  return (
    <div className="bd-wrapper position-relative">
      <div className="container">
        <h2 className="mx-auto position-relative">
          Come and Join MEKCO Group Aviation
        </h2>
        <span className="bd-button mx-auto position-relative">
          <a href="mailto:resumes@mekcogroup.com" style={{ color: "#f2994a" }}>
            Email Us
          </a>
        </span>
        <p className="position-relative">To find out what it takes.</p>
        <p className="two position-relative">
          <a href="mailto:resumes@mekcogroup.com">resumes@mekcogroup.com</a>
        </p>
      </div>
    </div>
  );
};

export default BannerDown;
